import { ethers } from 'ethers';
import config from '../config';
import { web3ProviderFrom } from '../emp-finance/ether-utils';

let provider: ethers.providers.Web3Provider = null;
let providerUrl: string = null;

export function getProviderUrl(): string {
  if (!providerUrl) {

    // const storedProviderUrl = sessionStorage.getItem('providerUrl');

    // if (storedProviderUrl) {
    //   providerUrl = storedProviderUrl;
    // } else {
    providerUrl = config.providers[Math.floor(Math.random() * config.providers.length)];
    // sessionStorage.setItem('providerUrl', providerUrl);
    // }
  }

  return providerUrl
}

export function resetProvider(): string {
  providerUrl = null;
  return getProviderUrl();
}

export function getDefaultProvider(): ethers.providers.Web3Provider {
  if (!provider) {
    provider = new ethers.providers.Web3Provider(web3ProviderFrom(getProviderUrl()), config.chainId);
  }

  return provider;
}
