import { DaasInfo } from './emp-finance/types';
import { Configuration } from './emp-finance/config';
import { BankInfo } from './emp-finance';

const configurations: { [env: string]: Configuration } = {
  // development: {
  //   chainId: 97,
  //   networkName: 'BSC Testnet',
  //   ftmscanUrl: 'https://testnet.bscscan.com/',
  //   defaultProvider: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  //   deployments: require('./emp-finance/deployments/deployments.testing.json'),
  //   externalTokens: {
  //     WBNB: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18],
  //     BUSD: ['0x55d398326f99059fF775485246999027B3197955', 18], // This is actually BUSD on mainnet not fusdt
  //     ETH: ['0xd66c6b4f0be8ce5b39d52e0fd1344c389929b378', 18],
  //     ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
  //     SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
  //     'USDT-BNB-LP': ['0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', 18],
  //     'EMP-ETH-LP': ['0x497D4b031d1A7fB76B75C99Ad0F9c86DbA7657Fb', 18],
  //     'ESHARE-BNB-LP': ['0xa90ccF2E01Be627ef0Ac1533d482E182D56ebe32', 18],
  //   },
  //   baseLaunchDate: new Date('2021-11-21 1:00:00Z'),
  //   bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
  //   boardroomLaunchesAt: new Date('2020-12-11T00:00:00Z'),
  //   refreshInterval: 10000,
  // },
  development: {
    chainId: 56,
    networkName: 'BSC Mainnet',
    ftmscanUrl: 'https://bscscan.com',
    providers: [
      'https://bsc-dataseed.bnbchain.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      // 'https://binance.llamarpc.com',
      // 'https://endpoints.omniatech.io/v1/bsc/mainnet/public',
      // 'https://bsc-pokt.nodies.app',
      // 'https://1rpc.io/bnb',
      // 'https://bsc-rpc.publicnode.com',
      // 'https://bsc.drpc.org',
      // 'https://bsc.meowrpc.com',
      // 'https://rpc.ankr.com/bsc',
    ],
    deployments: require('./emp-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WBNB: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18],
      BUSD: ['0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18],
      ETH: ['0x2170Ed0880ac9A755fd29B2688956BD959F933F8', 18],
      SETH: ['0x1d28cd41fc594232D05F2AbdAFBb556E7F78Dc2a', 18],
      SUSD: ['0x12017c89444624C0268A1053467e22954F4fd362', 18],
      SVL: ['0x37F14E7c2FadC2A01dBD93b8a1F69D41c6c3d554', 18],
      CAKE: ['0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', 18],
      ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
      SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
      EMP: ['0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58', 18],
      MDBPLUS: ['0x9f8BB16f49393eeA4331A39B69071759e54e16ea', 18],
      MDB: ['0x0557a288A93ed0DF218785F2787dac1cd077F8f3', 18],
      SPS: ['0x1633b7157e7638C4d6593436111Bf125Ee74703F', 18],
      xGRO: ['0x4AdAE3Ad22c4e8fb56D4Ae5C7Eb3abC0dd2d3379', 18],
      'USDT-BNB-LP': ['0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', 18],
      'USDT-ETH-LP': ['0x3f803ec2b816ea7f06ec76aa2b6f2532f9892d62', 18],
      'EMP-ETH-LP': ['0x84821bb588f049913Dc579Dc511E5e31EB22d5E4', 18],
      'EMP-BNB-LP': ['0xaf08211beed0266e6ce221f169f729f16cd7fadd', 18],
      'ESHAREv1-BNB-LP': ['0x1747AF98EBF0B22d500014c7dd52985d736337d2', 18],
      'ESHARE-BNB-LP': ['0x50A7BAd86bD493c128863c4df8a830417763F2d6', 18],
      'ESHARE-MDB+ LP': ['0x51586182A2031f3e384D080f78C8836D48eBBfFC', 18],
      'EMP-ESHARE-LP': ['0x2fa50016b979beeac3ef9242fe3031946b8f97e1', 18],
      'SPS-BNB-LP': ['0xFdFde3aF740A22648B9dD66D05698e5095940850', 18],
      'xGRO-BNB-LP': ['0xdc612572219e50f5759A441BC9E6F14C92116757', 18],
    },
    baseLaunchDate: new Date('2022-01-17T23:00:00Z'),
    bondLaunchesAt: new Date('2022-01-31T23:00:00Z'),
    boardroomLaunchesAt: new Date('2022-01-21T01:00:00Z'),
    esharesLaunchesAt: new Date('2022-01-20T01:00:00Z'),
    refreshInterval: 10000,
    spreadsheet: '1i262kO4f3w7_NSdYYzJpLRhLed7PWf9YeZun1J-PJfo'
  },
  production: {
    chainId: 56,
    networkName: 'BSC Mainnet',
    ftmscanUrl: 'https://bscscan.com',
    providers: [
      'https://bsc-dataseed.bnbchain.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      // 'https://binance.llamarpc.com',
      // 'https://endpoints.omniatech.io/v1/bsc/mainnet/public',
      // 'https://bsc-pokt.nodies.app',
      // 'https://1rpc.io/bnb',
      // 'https://bsc-rpc.publicnode.com',
      // 'https://bsc.drpc.org',
      // 'https://bsc.meowrpc.com',
      // 'https://rpc.ankr.com/bsc',
    ], deployments: require('./emp-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WBNB: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18],
      BUSD: ['0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18],
      ETH: ['0x2170Ed0880ac9A755fd29B2688956BD959F933F8', 18],
      SETH: ['0x1d28cd41fc594232D05F2AbdAFBb556E7F78Dc2a', 18],
      SUSD: ['0x12017c89444624C0268A1053467e22954F4fd362', 18],
      SVL: ['0x37F14E7c2FadC2A01dBD93b8a1F69D41c6c3d554', 18],
      CAKE: ['0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', 18],
      ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
      SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
      EMP: ['0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58', 18],
      MDBPLUS: ['0x9f8BB16f49393eeA4331A39B69071759e54e16ea', 18],
      MDB: ['0x0557a288A93ed0DF218785F2787dac1cd077F8f3', 18],
      SPS: ['0x1633b7157e7638C4d6593436111Bf125Ee74703F', 18],
      xGRO: ['0x4AdAE3Ad22c4e8fb56D4Ae5C7Eb3abC0dd2d3379', 18],
      'USDT-BNB-LP': ['0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', 18],
      'USDT-ETH-LP': ['0x3f803ec2b816ea7f06ec76aa2b6f2532f9892d62', 18],
      'EMP-ETH-LP': ['0x84821bb588f049913Dc579Dc511E5e31EB22d5E4', 18],
      'EMP-BNB-LP': ['0xaf08211beed0266e6ce221f169f729f16cd7fadd', 18],
      'ESHAREv1-BNB-LP': ['0x1747AF98EBF0B22d500014c7dd52985d736337d2', 18],
      'ESHARE-BNB-LP': ['0x50A7BAd86bD493c128863c4df8a830417763F2d6', 18],
      'ESHARE-MDB+ LP': ['0x51586182A2031f3e384D080f78C8836D48eBBfFC', 18],
      'EMP-ESHARE-LP': ['0x2fa50016b979beeac3ef9242fe3031946b8f97e1', 18],
      'SPS-BNB-LP': ['0xFdFde3aF740A22648B9dD66D05698e5095940850', 18],
      'xGRO-BNB-LP': ['0xdc612572219e50f5759A441BC9E6F14C92116757', 18],
    },
    baseLaunchDate: new Date('2022-01-17T23:00:00Z'),
    bondLaunchesAt: new Date('2022-01-31T23:00:00Z'),
    boardroomLaunchesAt: new Date('2022-01-21T01:00:00Z'),
    esharesLaunchesAt: new Date('2022-01-20T01:00:00Z'),
    refreshInterval: 10000,
    spreadsheet: '1i262kO4f3w7_NSdYYzJpLRhLed7PWf9YeZun1J-PJfo'
  },
};

export const daasDefinitions: { [contractName: string]: DaasInfo } = {
  Generator: {
    name: 'Generator',
    contract: 'Generator',
    tokenName: 'SPS-BNB-LP',
    tokenImage: 'generator-lp.png',
    backgroundVideo: 'generator.mp4',
    cardImage: 'generator-screenshot.png',
    bannerImage: 'splinterlands-banner.jpg',
    bannerUrl: 'https://bit.ly/SPLINTERLANDS-EMP',
    docs: 'https://empmoneyv2.gitbook.io/emp.money/detonator-as-a-service/generator-sps-bnb-lp',
    startTimestamp: 0,
    active: true,
  },
  Cultivator: {
    name: 'Cultivator',
    contract: 'Cultivator',
    tokenName: 'xGRO-BNB-LP',
    tokenImage: 'xgro-bnb-lp.png',
    backgroundVideo: 'cultivator.mp4',
    cardImage: 'cultivator-screenshot.png',
    mobileVideoPosition: 'left',
    docs: 'https://empmoneyv2.gitbook.io/emp.money/detonator-as-a-service/cultivator-xgro-bnb-lp',
    startTimestamp: 1665099000 - 86400 + 3600,
    active: true,
  }
}

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding EMP
        - 2 = LP asset staking rewarding ESHARE
        - 3 = EMP Masternode
        - 4 = ESHARE Locker
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  // EmpETHApeLPEmpRewardPool: {
  //   name: 'Earn EMP by EMP-ETH Ape LP',
  //   poolId: 2,
  //   sectionInUI: 1,
  //   contract: 'EmpETHApeLPEmpRewardPool',
  //   depositTokenName: 'EMP-ETH-APELP',
  //   earnTokenName: 'EMP',
  //   finished: true,
  //   sort: 2,
  //   closedForStaking: true,
  // },



  EmpMasterNode: {
    name: 'Generate EMP with Nodes',
    poolId: 4,
    sectionInUI: 3,
    contract: 'EmpMasterNode',
    depositTokenName: 'EMP',
    earnTokenName: 'EMP',
    finished: false,
    sort: 2,
    closedForStaking: false,
  },

  EShareLocker: {
    name: 'Earn ESHARE by Locking',
    poolId: 2,
    sectionInUI: 4,
    contract: 'EShareLocker',
    depositTokenName: 'ESHARE',
    earnTokenName: 'ESHARE',
    finished: false,
    sort: 9,
    closedForStaking: false,
  },



  // EmpCakeRewardPool: {
  //   name: 'Earn EMP by CAKE',
  //   poolId: 0,
  //   sectionInUI: 0,
  //   contract: 'EmpCAKERewardPool',
  //   depositTokenName: 'CAKE',
  //   earnTokenName: 'EMP',
  //   finished: true,
  //   sort: 3,
  //   closedForStaking: true,
  // },
  // EmpSETHRewardPool: {
  //   name: 'Earn EMP by SETH',
  //   poolId: 2,
  //   sectionInUI: 0,
  //   contract: 'EmpSETHRewardPool',
  //   depositTokenName: 'SETH',
  //   earnTokenName: 'EMP',
  //   finished: true,
  //   sort: 4,
  //   closedForStaking: true,
  // },
  // EmpSUSDRewardPool: {
  //   name: 'Earn EMP by SUSD',
  //   poolId: 1,
  //   sectionInUI: 0,
  //   contract: 'EmpSUSDRewardPool',
  //   depositTokenName: 'SUSD',
  //   earnTokenName: 'EMP',
  //   finished: true,
  //   sort: 5,
  //   closedForStaking: true,
  // },
  // EmpSVLRewardPool: {
  //   name: 'Earn EMP by SVL',
  //   poolId: 3,
  //   sectionInUI: 0,
  //   contract: 'EmpSVLRewardPool',
  //   depositTokenName: 'SVL',
  //   earnTokenName: 'EMP',
  //   finished: true,
  //   sort: 6,
  //   closedForStaking: true,
  // },

  // EmpGenesisRewardPool: {
  //   name: 'Earn EMP by WBNB',
  //   poolId: 0,
  //   sectionInUI: 0,
  //   contract: 'EmpGenesisRewardPool',
  //   depositTokenName: 'WBNB',
  //   earnTokenName: 'EMP',
  //   finished: false,
  //   sort: 1,
  //   closedForStaking: false,
  // },
  // EmpWBNBGenesisRewardPool: {
  //   name: 'Earn EMP by WBNB',
  //   poolId: 4,
  //   sectionInUI: 0,
  //   contract: 'EmpWBNBGenesisRewardPool',
  //   depositTokenName: 'WBNB',
  //   earnTokenName: 'EMP',
  //   finished: true,
  //   sort: 1,
  //   closedForStaking: true,
  // },
  // EmpBnbLPRewardPool: {
  //   name: 'Earn EMP by EMP-BNB LP',
  //   poolId: 1,
  //   sectionInUI: 1,
  //   contract: 'EmpBnbLPRewardPool',
  //   depositTokenName: 'EMP-BNB-LP',
  //   earnTokenName: 'EMP',
  //   finished: false,
  //   sort: 8,
  //   closedForStaking: false,
  // },
  // EmpShibaRewardPool: {
  //   name: 'Earn EMP by SHIBA',
  //   poolId: 2,
  //   sectionInUI: 0,
  //   contract: 'EmpShibaGenesisRewardPool',
  //   depositTokenName: 'SHIBA',
  //   earnTokenName: 'EMP',
  //   finished: false,
  //   sort: 3,
  //   closedForStaking: true,
  // },
  // EmpZooRewardPool: {
  //   name: 'Earn EMP by ZOO',
  //   poolId: 3,
  //   sectionInUI: 0,
  //   contract: 'EmpZooGenesisRewardPool',
  //   depositTokenName: 'ZOO',
  //   earnTokenName: 'EMP',
  //   finished: false,
  //   sort: 4,
  //   closedForStaking: true,
  // },

  // EmpFtmLPEmpRewardPoolOld: {
  //   name: 'Earn EMP by EMP-BNB LP',
  //   poolId: 0,
  //   sectionInUI: 1,
  //   contract: 'EmpFtmLpEmpRewardPoolOld',
  //   depositTokenName: 'EMP-BNB-LP',
  //   earnTokenName: 'EMP',
  //   finished: true,
  //   sort: 9,
  //   closedForStaking: true,
  // },
  // EmpFtmLPEShareRewardPool: {
  //   name: 'Earn ESHARE by EMP-BNB LP',
  //   poolId: 0,
  //   sectionInUI: 2,
  //   contract: 'EmpFtmLPEShareRewardPool',
  //   depositTokenName: 'EMP-BNB-LP',
  //   earnTokenName: 'ESHARE',
  //   finished: false,
  //   sort: 6,
  //   closedForStaking: false,
  // },

  EShareBnbEShareRewardPoolV2: {
    name: 'Earn ESHARE by ESHARE-BNB LP',
    poolId: 0,
    sectionInUI: 2,
    contract: 'EShareBnbEShareRewardPoolV2',
    depositTokenName: 'ESHARE-BNB-LP',
    earnTokenName: 'ESHARE',
    finished: true,
    sort: 0,
    closedForStaking: true,
  },
  EmpEthEShareRewardPoolV2: {
    name: 'Earn ESHARE by EMP-ETH LP',
    poolId: 1,
    sectionInUI: 2,
    contract: 'EmpEthEShareRewardPoolV2',
    depositTokenName: 'EMP-ETH-LP',
    earnTokenName: 'ESHARE',
    finished: false,
    sort: 1,
    closedForStaking: false,
  },


  EShareBnbEShareRewardPool: {
    name: 'Earn ESHAREv1 by ESHARE-BNB LP',
    poolId: 0,
    sectionInUI: 2,
    contract: 'EShareBnbEShareRewardPool',
    depositTokenName: 'ESHAREv1-BNB-LP',
    earnTokenName: 'ESHAREv1',
    finished: false,
    sort: 8,
    closedForStaking: true,
  },
  EmpEthEShareRewardPool: {
    name: 'Earn ESHAREv1 by EMP-ETH LP',
    poolId: 1,
    sectionInUI: 2,
    contract: 'EmpEthEShareRewardPool',
    depositTokenName: 'EMP-ETH-LP',
    earnTokenName: 'ESHAREv1',
    finished: false,
    sort: 7,
    closedForStaking: true,
  },
  EmpEShareEShareRewardPool: {
    name: 'Earn ESHAREv1 by EMP-ESHARE LP',
    poolId: 2,
    sectionInUI: 2,
    contract: 'EmpEShareEShareRewardPool',
    depositTokenName: 'EMP-ESHARE-LP',
    earnTokenName: 'ESHAREv1',
    finished: false,
    sort: 10,
    closedForStaking: true,
  },
  EmpEShareRewardPool: {
    name: 'Earn ESHAREv1 by EMP',
    poolId: 3,
    sectionInUI: 0,
    contract: 'EmpEShareRewardPool',
    depositTokenName: 'EMP',
    earnTokenName: 'ESHAREv1',
    finished: true,
    sort: 11,
    closedForStaking: true,
  },
  EShareMdbEShareRewardPool: {
    name: 'Earn ESHAREv1 by ESHARE-MDB+ LP',
    poolId: 4,
    sectionInUI: 2,
    contract: 'EShareMdbEShareRewardPool',
    depositTokenName: 'ESHARE-MDB+ LP',
    earnTokenName: 'ESHAREv1',
    finished: false,
    sort: 9,
    closedForStaking: true,
  },

  // EmpEthLPApeEShareRewardPool: {
  //   name: 'Earn ESHARE by EMP-ETH LP',
  //   poolId: 3,
  //   sectionInUI: 2,
  //   contract: 'EmpEthLPApeEShareRewardPool',
  //   depositTokenName: 'EMP-ETH-LP',
  //   earnTokenName: 'ESHARE',
  //   finished: false,
  //   sort: 7,
  //   closedForStaking: false,
  // },
  // EshareBnbApeLPEShareRewardPool: {
  //   name: 'Earn ESHARE by ESHARE-BNB Ape LP',
  //   poolId: 2,
  //   sectionInUI: 1,
  //   contract: 'EshareBnbApeLPEShareRewardPool',
  //   depositTokenName: 'ESHARE-BNB-APELP',
  //   earnTokenName: 'ESHARE',
  //   finished: true,
  //   sort: 5,
  //   closedForStaking: true,
  // },
  // EmpEthApeLPEShareRewardPool: {
  //   name: 'Earn ESHARE by EMP-ETH Ape LP',
  //   poolId: 3,
  //   sectionInUI: 2,
  //   contract: 'EmpEthApeLPEShareRewardPool',
  //   depositTokenName: 'EMP-ETH-APELP',
  //   earnTokenName: 'ESHARE',
  //   finished: true,
  //   sort: 4,
  //   closedForStaking: true,
  // },
  // EshareBnbLPEShareRewardPool: {
  //   name: 'Earn ESHARE by ESHARE-BNB LP',
  //   poolId: 0,
  //   sectionInUI: 2,
  //   contract: 'EshareBnbLPEShareRewardPool',
  //   depositTokenName: 'ESHARE-BNB-LP',
  //   earnTokenName: 'ESHARE',
  //   finished: false,
  //   sort: 2,
  //   closedForStaking: false,
  // },
  // EmpEthLPEShareRewardPool: {
  //   name: 'Earn ESHARE by EMP-ETH LP',
  //   poolId: 1,
  //   sectionInUI: 2,
  //   contract: 'EmpEthLPEShareRewardPool',
  //   depositTokenName: 'EMP-ETH-LP',
  //   earnTokenName: 'ESHARE',
  //   finished: false,
  //   sort: 1,
  //   closedForStaking: false,
  // },
};

export default configurations[process.env.NODE_ENV || 'development'];
