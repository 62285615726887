export const EMP_TICKER = 'EMP';
export const ESHARE_TICKER = 'ESHARE';
export const EBOND_TICKER = 'EBOND';
export const BNB_TICKER = 'BNB';
export const WBNB_TICKER = 'WBNB';
export const ETH_TICKER = 'ETH';
export const BUSD_TICKER = 'BUSD';
export const SPS_TICKER = 'SPS';
export const XGRO_TICKER = 'xGRO';
export const MDB_TICKER = 'MDB';

export const SPOOKY_ROUTER_ADDR = '0x10ed43c718714eb63d5aa57b78b54704e256024e';
export const ZAPPER_ROUTER_ADDR = '0x2549f981D5deB2184b56C3E839847CbdB351DE91';
export const TAX_OFFICE_ADDR = '0x12A9691B3BD61f0d235cf95676D6a7a555164768';
